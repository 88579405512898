<template>
    <div>
        <b-row class="mt-3">
            <b-col cols="12" class="d-flex justify-content-center align-item-center">
            </b-col>
            <b-col>
                <template>
                    <page1 v-if="currentPage==1"  @next="next($event)" @back="next($event)"/>
                </template>
            </b-col>
        </b-row>

    </div>
</template>
<script>

import Page1 from "@/modules/workOrderRequestForm/pages/page1.vue";

export default {
    components: {Page1},
    data() {
        return {
            currentPage: 1,
        }
    },

    
}
</script>
