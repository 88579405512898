<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row class="m-3 w-100">
                <b-col>
                    <b-row class="align-items-center justify-content-center mb-3">
                        <!-- Logo -->
                        <b-col sm="auto" class="text-center">
                            <img src="../../../assets/img/island-condos-logo.png.png" alt="Island Condos Logo"
                                height="120" />
                        </b-col>
                        <!-- Header -->
                        <b-col sm="auto" class="text-center">
                            <h3>{{ $t("work_order_request_form") }}</h3>
                        </b-col>
                    </b-row>

                    <!-- Form Content -->
                    <b-row class="d-flex justify-content-center">
                        <b-col sm="12" md="8" lg="6" class="mb-3">
                            <b-card cols="4" :title="$t('Information')" class="card-size">
                                <b-row>
                                    <b-col>
                                        <b-form-group :label="this.$t('name')">
                                            <b-form-input />
                                        </b-form-group>
                                    </b-col>
                                    <b-col>
                                        <ValidationProvider name="birthdate" rules="required"
                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('date')" class="position-relative">
                                                <v-date-picker v-model="form.birthdate" :locale="'EN'" is-expanded
                                                    :masks="masks" :max-date="new Date()" :popover="{
                                                        visibility: 'click',
                                                    }">
                                                    <template v-slot="{
                                                        inputValue,
                                                        inputEvents,
                                                    }">
                                                        <b-input-group>
                                                            <b-form-input :value="inputValue
                                                                " v-on="inputEvents
                                                                    " :state="errors[0]
                                                                        ? false
                                                                        : valid
                                                                            ? true
                                                                            : null
                                                                    ">
                                                            </b-form-input>
                                                            <b-input-group-append>
                                                                <b-button variant="outline-secondary" class="btn-40"
                                                                    disabled>
                                                                    <i class="ri-calendar-line"></i>
                                                                </b-button>
                                                            </b-input-group-append>
                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]
                                                                "></b-form-invalid-feedback>
                                                        </b-input-group>
                                                    </template>
                                                </v-date-picker>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <ValidationProvider name="email" rules="required|email"
                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('email')">
                                                <b-form-input type="text" :state="errors[0]
                                                        ? false
                                                        : valid
                                                            ? true
                                                            : null
                                                    ">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    :v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col>
                                        <ValidationProvider name="gsmNo" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('telephone')">
                                                <b-form-input v-mask="'+1 (###)-###-####'" :state="errors[0]
                                                        ? false
                                                        : valid
                                                            ? true
                                                            : null
                                                    ">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <ValidationProvider name="requestDetails" rules="required"
                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('request_details')">
                                                <b-form-textarea v-model="form.requestDetails
                                                    " rows="5" :state="errors[0]
                                                            ? false
                                                            : valid
                                                                ? true
                                                                : null
                                                        ">
                                                </b-form-textarea>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <ValidationProvider name="location" rules="required" v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('location')">
                                                <b-form-input type="text" v-model="form.location" :state="errors[0]
                                                        ? false
                                                        : valid
                                                            ? true
                                                            : null
                                                    " style="width: 100%">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                    v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('category')">
                                            <b-form-radio-group v-model="form.category" stacked>
                                                <b-row>
                                                    <b-col sm="4">
                                                        <b-form-radio value="Automotive">{{
                                                            $t("automotive")
                                                        }}</b-form-radio>
                                                        <b-form-radio value="Custodial">{{
                                                            $t("Custodial")
                                                        }}</b-form-radio>
                                                        <b-form-radio value="Electrical">{{
                                                            $t("electrical")
                                                        }}</b-form-radio>
                                                        <b-form-radio value="Furniture">{{
                                                            $t("furniture")
                                                        }}</b-form-radio>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <b-form-radio value="Grounds">{{
                                                            $t("grounds")
                                                        }}</b-form-radio>
                                                        <b-form-radio value="HVAC">{{
                                                            $t("HVAC")
                                                        }}</b-form-radio>
                                                        <b-form-radio value="Painting">{{
                                                            $t("painting")
                                                        }}</b-form-radio>
                                                        <b-form-radio value="Pest Control">{{
                                                            $t(
                                                                "pest_control"
                                                            )
                                                        }}</b-form-radio>
                                                    </b-col>
                                                    <b-col sm="4">
                                                        <b-form-radio value="Plumbing">{{
                                                            $t("plumbing")
                                                        }}</b-form-radio>
                                                        <b-form-radio value="Safety/Security">{{
                                                            $t("safety") + "/" + $t("security")
                                                        }}</b-form-radio>
                                                        <b-form-radio value="Other">{{
                                                            $t("other")
                                                        }}</b-form-radio>
                                                    </b-col>
                                                </b-row>
                                            </b-form-radio-group>

                                            <!-- Eğer "Other" radio button'ı seçildiyse bu input görünsün -->
                                            <b-form-input v-if="form.category === 'Other'" v-model="form.otherCategory"
                                                placeholder="Please specify" class="mt-3">
                                            </b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <hr />
                                <h5>{{ $t("For office use only:") }}</h5>

                                <b-row>
                                    <b-col>
                                        <ValidationProvider name="completionDate" rules="required"
                                            v-slot="{ valid, errors }">
                                            <b-form-group :label="$t('completion_date')">
                                                <v-date-picker v-model="form.completionDate
                                                    " :locale="'EN'" is-expanded :masks="masks" :popover="{
                                                        visibility: 'click',
                                                    }">
                                                    <template v-slot="{
                                                        inputValue,
                                                        inputEvents,
                                                    }">
                                                        <b-input-group>
                                                            <b-form-input :value="inputValue
                                                                " v-on="inputEvents
                                                                    " :state="errors[0]
                                                                        ? false
                                                                        : valid
                                                                            ? true
                                                                            : null
                                                                    ">
                                                            </b-form-input>
                                                            <b-input-group-append>
                                                                <b-button variant="outline-secondary" class="btn-40"
                                                                    disabled>
                                                                    <i class="ri-calendar-line"></i>
                                                                </b-button>
                                                            </b-input-group-append>
                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]
                                                                "></b-form-invalid-feedback>
                                                        </b-input-group>
                                                    </template>
                                                </v-date-picker>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <b-form-group :label="$t('urgency')" style="font-size: 20px">
                                            <b-form-radio-group v-model="form.urgency" stacked>
                                                <b-form-radio value="Low">{{
                                                    $t("low")
                                                    }}</b-form-radio>
                                                <b-form-radio value="Medium">{{
                                                    $t("medium")
                                                    }}</b-form-radio>
                                                <b-form-radio value="High">{{
                                                    $t("high")
                                                    }}</b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                birthdate: null,
                location: "",
                category: [],
                otherCategory: "", // Other's input
                requestDetails: "",
            },
            masks: {
                input: "DD-MM-YYYY",
            },
        };
    },
};
</script>
